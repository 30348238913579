import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import "./FeedBackStyle.css";
import WelcomePage from "./components/WelcomePage";
import { Form, Formik } from "formik";
import { FPButton } from "../../components/common/new";
import {
  BackArrow,
  DistanceIcon,
  FamePilotIcon,
  FeedBackIcon,
  LocationIcon,
} from "../../images/svg";
import { Box } from "@material-ui/core";
import FeedbackAutoComplete from "./components/FormContainer/FeedbackAutoComplete";
import * as yup from "yup";
import UserDetailsForm from "./components/UserDetailsForm";
import UserExperiencePage from "./components/UserExperiencePage";
import SurveyPage from "./components/SurveyPage";
import GoodByPage from "./components/GoodByPage";
import apiUrls from "../../libs/apiUrls";
import { bindActionCreators } from "redux";
import * as filterListActions from "../../actions/common/filterListActions";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../../actions/common/commonAction";
import { pad } from "highcharts";

const survey_page = [
  {
    title: "Survey Page 1",
    page_id: 2619,
    allQuestions: [
      {
        input: "How would you rate the value of our food? ",
        question_short_form: "please rate our food",
        question_type: "RATING",
        question_required: true,
        question_id: 2842,
        lowRatingInput: "Poor value",
        HighRatingInput: "Great Value",
        options: [],
      },
      {
        input: "Who was your server today?",
        question_short_form: "please explain with few lines",
        question_type: "SHORT ANSWER",
        question_required: true,
        question_id: 2843,
        options: [],
      },
    ],
  },
  {
    title: "Survey Page 2",
    page_id: 2620,
    allQuestions: [
      {
        input: "Do you find that our restaurant is family-friendly? ",
        question_short_form: "write some lines ?",
        question_type: "PARAGRAPH",
        question_required: true,
        question_id: 2844,
        options: [],
      },
      {
        input: "How likely is it that you would come back?",
        question_short_form: "please rate ",
        question_type: "NPS QUESTION",
        question_required: true,
        question_id: 2845,
        lowRatingInput: "Likely",
        HighRatingInput: "Unlikely",
        options: [],
      },
    ],
  },
  {
    title: "Survey Page 3",
    page_id: 2621,
    allQuestions: [
      {
        input: "Chinese Food",
        question_short_form: "Which one is your favourite?",
        question_type: "Drop-down",
        question_required: false,
        question_id: 2846,
        options: [
          {
            title: "Manchurian",
            id: 6537,
          },
          {
            title: "Momos",
            id: 6538,
          },
          {
            title: "Noodles",
            id: 6539,
          },
          {
            title: "Spring rolls",
            id: 6540,
          },
        ],
      },
      {
        input: "Which food do you like most?",
        question_short_form: "Please select best one?",
        question_type: "Checkbox",
        question_required: false,
        question_id: 2847,
        options: [
          {
            title: "Amritsari naan",
            id: 6541,
          },
          {
            title: "Dal Makhni",
            id: 6542,
          },
          {
            title: "Butter Paneer",
            id: 6543,
          },
          {
            title: "Lacha Parathna",
            id: 6544,
          },
        ],
      },
    ],
  },
  {
    title: "Survey Page 4",
    page_id: 2622,
    allQuestions: [
      {
        input: "Which is your favorite desert?",
        question_short_form: "select the best one",
        question_type: "Multiple Choice",
        question_required: false,
        question_id: 2848,
        options: [
          {
            title: "Ice Cream",
            id: 6545,
          },
          {
            title: "Custards and Puddings",
            id: 6546,
          },
          {
            title: "Pastries",
            id: 6547,
          },
          {
            title: "Gulab Jamun",
            id: 6548,
          },
        ],
      },
    ],
  },
  {
    title: "Survey Page 5",
    page_id: 6991,
    allQuestions: [
      {
        input: "Please attach your best dish for the day.",
        question_short_form: "Please attach your best dish for the day.",
        question_type: "ATTACHMENT",
        question_required: false,
        question_id: 12226,
        options: [],
      },
      {
        input: "Attach your Dish video  ?",
        question_short_form: "Attach your Dish video  ?",
        question_type: "ATTACHMENT",
        question_required: false,
        question_id: 12227,
        options: [],
      },
    ],
  },
];

const review_channel = {
  google: {
    link: "https://maps.google.com/?cid=3759227184787829546",
    logo: "https://api.famepilot.com/static/images/review/google.png",
    title: "google",
  },
  tripadvisor: {
    link: "https://www.tripadvisor.com/UserReviewEdit-g189117-d7989747--Delhi_Darbar-Lagos_Faro_District_Algarve.html",
    logo: "https://api.famepilot.com/static/images/review/tripadvisor.png",
    title: "tripadvisor",
  },
  zomato: {
    link: "https://zoma.to/r/18606285",
    logo: "https://api.famepilot.com/static/images/review/zomato.png",
    title: "zomato",
  },
};
function FormikStep({ children, title, description, contentCenter }) {
  return contentCenter ? (
    <>
      <div className="logo-container">
        <img
          src="https://api.famepilot.com/media/360_F_276560883_lqejdalZAZcXDfCAFDFUnTe9ktOXSRD4.jpg"
          alt="Company Logo"
          className="logo"
        />
      </div>

      {title && <h2 className="page-title">{title}</h2>}

      <div className="page-content">{children}</div>

      {description && <p className="page-description mt-4">{description}</p>}
    </>
  ) : (
    <>
      <div>
        <div className="logo-container">
          <img
            src="https://api.famepilot.com/media/360_F_276560883_lqejdalZAZcXDfCAFDFUnTe9ktOXSRD4.jpg"
            alt="Company Logo"
            className="logo"
          />
        </div>

        {title && <h2 className="page-title">{title}</h2>}

        <div className="page-content">{children}</div>

        {description && <p className="page-description mt-4">{description}</p>}
      </div>
    </>
  );
}

function FormikStepper({ children, setInitialValues, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  function isLastStep() {
    return step === childrenArray.length - 2;
  }
  // const setInitialValue = (value) => {
  //   const initialValues = {};
  //   if (step >= 3) {
  //     survey_page[step - 3]?.allQuestions?.map((item) => {
  //       if (value[item?.question_id?.toString()]) {
  //         initialValues[item?.question_id?.toString()] =
  //           value[item?.question_id?.toString()];
  //       } else {
  //         initialValues[item?.question_id?.toString()] = "";
  //       }
  //     });
  //     setInitialValues({ ...props.initialValues, ...initialValues });
  //   }
  // };

  return (
    <Formik
      {...props}
      initialValues={props.initialValues}
      // validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        // setInitialValue(values);
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep((s) => s + 1);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off" className="m-auto">
          <div
            className="page-card fade-in"
            style={{ borderRadius: "12px", boxShadow: "var(--box-shadow)" }}
          >
            {currentChild}
            <div style={{ padding: "0% 5%", marginTop: "20px" }}>
              {!(step === childrenArray.length - 1) && (
                <>
                  <div className="mb-2">
                    <div className="button-container">
                      {step > 1 && (
                        <div
                          style={{ width: "38px" }}
                          onClick={() => setStep((s) => s - 1)}
                        >
                          <BackArrow
                            style={{
                              width: "100%",
                              height: "auto",
                              border: "1px solid black",
                              borderRadius: "49px",
                              padding: "7px",
                            }}
                          />
                        </div>
                      )}
                      <FPButton
                        label={
                          step === 0
                            ? "Start a 1 minute survey"
                            : isLastStep()
                            ? "Submit"
                            : "Next"
                        }
                        className={"w-100"}
                        type="submit"
                        endIcon={
                          !isLastStep() && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M5 12h14M12 5l7 7-7 7" />
                            </svg>
                          )
                        }
                      />
                    </div>

                    {step > 0 && (
                      <div className="d-flex gap-10 mt-2">
                        <div className="feed-date-marker"></div>
                        <div className="page-indicator">
                          Page {step} of {childrenArray.length - 2}
                        </div>
                        <div className="feed-date-marker"></div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="d-flex justify-content-center align-center mt-2 gap-4">
                <p className="m-0"> Powered by</p>
                <div style={{ width: "70px" }}>
                  <FamePilotIcon height={"100%"} width={"100%"} />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const GenericFeedbackPage = (props) => {
  const {
    filterAction,
    filter,
    match: {
      params: { template_id },
    },
    commonActions,
  } = props;
  const [initialValues, setInitialValues] = useState({
    state: "",
    city: "",
    branch: "",
    user_name: "",
    user_number: "",
    user_email: "",
    user_gender: "",
    start_rating: "",
    user_experience: "",
    emoji_rating: "",
    nps_score: "",
    user_terms: "",
  });

  useEffect(() => {
    if (survey_page?.length) {
      const initialValues = survey_page.reduce((acc, item) => {
        item.allQuestions.forEach((question) => {
          acc[question.question_id.toString()] = "";
        });
        return acc;
      });

      setInitialValues((prev) => ({ ...prev, ...initialValues }));
    }
  }, [survey_page]);

  // useEffect(() => {
  //   let businessId;
  //   axios_instance
  //     .get(`${apiUrls.REVIEWS_DETAILS}${template_id}/feedback/`)
  //     .then((res) => {
  //       businessId = res.data.business.id;
  //       filterAction.getBranch(
  //         "",
  //         `${apiUrls.BUSINESS_SETUP}${businessId}/branch/`
  //       );
  //       commonActions.cityOptions(businessId);
  //       commonActions.stateOptions(businessId);
  //     });
  // }, []);

  const stateOptions = [
    { display_name: "Delhi", value: "delhi" },
    { display_name: "Maharashtra", value: "maharashtra" },
    { display_name: "Karnataka", value: "karnataka" },
    { display_name: "Tamil Nadu", value: "tamilnadu" },
  ];

  const cityOptions = [
    { display_name: "New Delhi", value: "new_delhi" },
    { display_name: "Mumbai", value: "mumbai" },
    { display_name: "Bangalore", value: "bangalore" },
    { display_name: "Chennai", value: "chennai" },
  ];

  const branchOptions = [
    { display_name: "Central Branch", value: "branch1" },
    { display_name: "East Wing", value: "branch2" },
    { display_name: "West Wing", value: "branch3" },
    { display_name: "North Extension", value: "branch4" },
  ];

  return (
    <div style={{height:"100vh"}}>
      <div
        className="template-bg full-height overflow-hidden"
        style={{
          backgroundColor: "#fff",
          backgroundSize:"cover",
display:"flex",
alignItems:"center",          
justifyContent:"center",
          //   backgroundImage: `url(${this.state.background_url})`,
        }}
      >
        <div className="page-navigator-container">
          <FormikStepper
            initialValues={initialValues}
            onSubmit={(values) => {
              console.log(values);
            }}
            setInitialValues={setInitialValues}
          >
            <FormikStep contentCenter={true}>
              <WelcomePage />
            </FormikStep>
            {true ? (
              <FormikStep
                title="Select your Location"
                validationSchema={yup.object().shape({
                  state: yup.string().required("Please Select State"),
                  city: yup.string().required("Please Select City"),
                  branch: yup.string().required("Please Select Location"),
                })}
              >
                <>
                  <FeedbackAutoComplete
                    options={stateOptions}
                    placeholder="State"
                    name="state"
                  />

                  <FeedbackAutoComplete
                    options={cityOptions}
                    placeholder="Select City"
                    name="city"
                  />
                  <FeedbackAutoComplete
                    options={branchOptions}
                    placeholder="Select Branch"
                    name="branch"
                  />
                </>
              </FormikStep>
            ) : (
              <FormikStep>
                {/* <SearchComponent {...props} endIcon placeholder="Search Location"/> */}
                <div className="feed-search-container">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Location"
                  />
                  <span className="search-icon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  </span>
                </div>

                <div className="location-list">
                  <div
                    className="location-item"
                    // onClick={() =>
                    // handleSelectLocation({
                    //   id: 1,
                    //   name: "Wow Momo - DB City Mall",
                    //   address: "Shop No.F5 & F.7, 3rd Floor, DB Mall, Arera Hills",
                    //   distance: "5.6 km",
                    // })
                    // }
                  >
                    <div className="location-title">
                      <FeedBackIcon />
                      Wow Momo - DB City Mall
                    </div>
                    <div className="location-address">
                      <LocationIcon />
                      Shop No.F5 & F.7, 3rd Floor, DB Mall, Arera Hills
                    </div>
                    <div className="location-distance">
                      <DistanceIcon />
                      5.6 km
                    </div>
                  </div>

                  <div
                    className="location-item"
                    // onClick={() =>
                    //   handleSelectLocation({
                    //     id: 2,
                    //     name: "Wow Momo - Gulmohar",
                    //     address:
                    //       "Shop No G2, Gr Flr, Sixth Avenue, Plot No A/31 & A/32 Roseville Kalon",
                    //     distance: "10.5 km",
                    //   })
                    // }
                  >
                    <div className="location-title">
                      <FeedBackIcon />
                      Wow Momo - DB City Mall
                    </div>
                    <div className="location-address">
                      <LocationIcon />
                      Shop No.F5 & F.7, 3rd Floor, DB Mall, Arera Hills
                    </div>
                    <div className="location-distance">
                      <DistanceIcon />
                      5.6 km
                    </div>
                  </div>
                </div>
              </FormikStep>
            )}
            <FormikStep
              title="Contact details"
              validationSchema={yup.object().shape({
                user_name: yup.string().required("Please Enter Name"),
                user_email: yup.string().required("Please Enter Email"),
                user_number: yup
                  .string()
                  .required("Please Enter Mobile Number"),
                user_gender: yup.string().required("Please Enter Gender"),
              })}
            >
              <UserDetailsForm />
            </FormikStep>

            <FormikStep
              title="How was your experience?"
              validationSchema={yup.object().shape({
                start_rating:
                  true && yup.string().required("Please fill in the details"),
                emoji_rating:
                  false && yup.string().required("Please fill in the details"),
                nps_score:
                  false && yup.string().required("Please fill in the details"),
                user_experience: yup
                  .string()
                  .required("Please fill in the details"),
              })}
            >
              <UserExperiencePage />
            </FormikStep>

            {survey_page &&
              survey_page?.length > 0 &&
              survey_page?.map((item, index) => {
                return (
                  <FormikStep
                    title="Survey"
                    key={item.page_id}
                    // validationSchema={yup.object().shape(
                    //   item.allQuestions.reduce((schema, question) => {
                    //     if (question.question_required) {
                    //       schema[question.question_id.toString()] =
                    //         question.question_type === "RATING"
                    //           ? yup
                    //               .number()
                    //               .required("Please fill in the details")
                    //           : yup
                    //               .string()
                    //               .required("Please fill in the details");
                    //     }
                    //     return schema;
                    //   }, {})
                    // )}
                  >
                    <SurveyPage item={item} pageIndex={index} />
                  </FormikStep>
                );
              })}

            <FormikStep>
              <GoodByPage links={review_channel} />
            </FormikStep>
          </FormikStepper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericFeedbackPage);
