import React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Box, Divider } from "@material-ui/core";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import UserDetails from "../../modules/review/UserDetails";
import {
  ReviewCardHeader,
  ReviewHeaderActivity,
  ReviewText,
} from "../../modules/review";
import FeedbackImage from "./FeedbackImage";
import CommentModal from "./CommentModal";
import CustomStarRatingComponent from "../common/StarComponent";
import * as utils from "../../libs/utils";
import whiteTag from "../../images/svg/whiteTag.svg";
import { ReactComponent as HorizontalArrow } from "../../images/svg/horizontalArrow.svg";
import timeClock from "../../images/svg/timeClock.svg";
import AddPlus from "../../images/svg/addPlus.svg";
import internalRemark from "../../images/svg/internalRemark.svg";
import FPChip from "../common/new/FPChip/FPChip";
import { FPAvatar, FPToolTip, MessageBox } from "../common/new";

function ReviewListSection({
  item,
  isCompetitors,
  service_category,
  isComplaints,
  showFetchOrderDetailsBtn,
  onAddOrderId,
  fetchOrderDetails,
  handleOrderDetails,
  replyDisabled,
  statuses,
  replyOptions,
  handleReplyOption,
  ticketActivity,
  toggleTicketModal,
  createTicket,
  toggle,
  changeStatus,
  searchText,
  commentDisabled,
  isPopupOpen,
  CurrentItemId,
  rId,
  location,
  handleReplay,
  responseOptions,
  fetchResponse,
  responseDetail,
  manageTemplates,
  getResponseOptions,
  businessId,
  is_review_aspect_attribute,
  hideButtons,
  tagsDisabled,
  handleReviewTagsChange,
  toggleConfirm,
  postTag,
  renderTagChip,
  viewTagActivityModal,
  review_tag_id,
  isNewTag,
  selectedTag,
  fameAiTagModal,
  remarkId,
  productTagModal,
  setState,
  addComment,
}) {
  const handleSetState = (newState) => {
    setState(newState);
  };

  const getUserName = (item) => {
    if (["swiggy", "inhouse"].includes(item.provider_name)) {
      return item?.reviewer?.name?.toString() || "";
    }
    if (item.provider_name === "zomato_ors") {
      return item.order_id;
    }
    return (
      item?.reviewer?.unique_code || item?.reviewer?.name?.toString() || ""
    );
  };

  return (
    <CardWrapper
      className="fp-card-outline review-card-wrapper"
      showDivider={false}
    >
      <ReviewCardHeader
        item={item}
        isCompetitors={isCompetitors}
        serviceCategory={service_category}
        smiley={utils.getSmileyType(item.smiley_value)}
        isComplaints={isComplaints}
      />
      <section className="review-card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <FPAvatar
            alt={item?.reviewer?.name}
            src={item?.reviewer?.image}
            labelClass={"user-name"}
            userStatus={item?.reviewer?.segmentation}
            typeChip={item?.reviewer?.type}
            subLabel={
              <UserDetails
                item={item}
                showFetchOrderDetailsBtn={showFetchOrderDetailsBtn}
                onAddOrderId={onAddOrderId}
                fetchOrderDetails={fetchOrderDetails}
                service_category={service_category}
                handleOrderDetails={handleOrderDetails}
              />
            }
            userName={getUserName(item)}
          />

          <ReviewHeaderActivity
            item={item}
            replyDisabled={replyDisabled}
            statuses={statuses}
            replyOptions={replyOptions}
            handleReplyOption={handleReplyOption}
            ticketActivity={ticketActivity}
            toggleTicketModal={toggleTicketModal}
            createTicket={createTicket}
            toggle={toggle}
            changeStatus={() =>
              changeStatus(
                item.ticket_status && item.ticket_status === "resolved"
                  ? "reopened"
                  : "resolved",
                item.t_id
              )
            }
          />
        </div>
        {!!item.heading && (
          <p className="review-details-heading m-0 mb-1 ">{item.heading}</p>
        )}

        {isPopupOpen &&
        CurrentItemId === item.id &&
        item.description &&
        item.description.length > 0 ? (
          <div className="rounded p-2 b-radius-8 fp-border">
            <ReviewText
              item={item}
              searchText={searchText}
              commentDisabled={commentDisabled}
            />
          </div>
        ) : (
          <ReviewText
            item={item}
            searchText={searchText}
            commentDisabled={commentDisabled}
          />
        )}

        {item.review_remark !== null && item.review_remark !== "" && (
          <div className="remark-details mt-3">
            <div style={{ width: "95%" }}>
              <p>
                <b>Remarks: </b>
                {item.review_remark}
              </p>
            </div>
            <div className="remark-details-icon">
              <BorderColorOutlinedIcon
                style={{
                  color: "#7A8193",
                  width: "16px",
                  height: "20px",
                }}
                role="button"
                onClick={() =>
                  handleSetState({
                    remarks: item.review_remark,
                    remarkId: item.id,
                    isRemarksModalOpen: true,
                  })
                }
              />
              <DeleteForeverOutlinedIcon
                style={{
                  color: "#7A8193",
                  width: "20px",
                  height: "18px",
                }}
                role="button"
                onClick={() =>
                  handleSetState({
                    confModalOpen: true,
                    remarkId: item.id,
                  })
                }
              />
            </div>
          </div>
        )}

        {item.extra_data.image_links &&
        item.extra_data.image_links.length !== 0 ? (
          <FeedbackImage reviews={item.extra_data} />
        ) : (
          ""
        )}
        <Divider className="my-3" style={{ background: "#F6F6F8" }} />

        {isPopupOpen && CurrentItemId === item.id && (
          <CommentModal
            description={item.description}
            location={location}
            addComment={(data) => {
              return addComment(data);
            }}
            toggle={toggle}
            handleReplyOption={handleReplyOption}
            selectedItem={item}
            responseOptions={responseOptions}
            fetchResponse={fetchResponse}
            responseDetail={responseDetail}
            manageTemplates={manageTemplates}
            getResponseOptions={getResponseOptions}
            review_id={rId}
            bId={businessId}
            onClose={() => handleSetState({ isPopupOpen: false })}
          />
        )}

        <div
          className={`d-flex  ${
            item.review_remark == null || item.review_remark == ""
              ? "justify-content-end"
              : "justify-content-end"
          }`}
        ></div>

        <section className="d-flex gap-10 align-items-start justify-content-between mt-2">
          <div
            style={{
              paddingRight:
                isPopupOpen && CurrentItemId === item.id ? "230px" : "0px",
            }}
          >
            <div className="d-flex gap-10">
              {(item.review_remark == null || item.review_remark == "") && (
                <FPToolTip title={"Add Internal Remarks"} className="me-3">
                  <div className="makeRemarkIcon">
                    <img
                      src={internalRemark}
                      onClick={() =>
                        handleSetState({
                          isRemarksModalOpen: true,
                          remarkId: item.id,
                        })
                      }
                      alt=""
                      loading="lazy"
                      className="size-15"
                    />
                  </div>
                </FPToolTip>
              )}

              {!hideButtons && (
                <FPToolTip title="Manual Tags">
                  <div className="tagIcon">
                    <img
                      src={whiteTag}
                      onClick={(e) => {
                        handleReviewTagsChange(e, item);
                      }}
                      alt=""
                      loading="lazy"
                      className="size-15"
                    />
                  </div>
                </FPToolTip>
              )}
              {!!(item?.tag?.length > 0) && !tagsDisabled && (
                <CSSTransitionGroup
                  transitionName="reviews-tags"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={500}
                  className="d-flex flex-wrap gap-10 align-items-center"
                >
                  {item.tag.map((tag, index) => {
                    return (
                      <FPChip
                        key={`${index}-review-tags`}
                        size="small"
                        variant="outlined"
                        style={{
                          borderColor: "#BFC2CB",
                          borderWidth: "1.5px",
                          maxHeight: "24px",
                        }}
                        className="review-tag-chip"
                        label={
                          <span style={{ fontSize: "10.5px" }}>
                            {utils.snakeToCapital(tag)}
                          </span>
                        }
                        onDelete={() =>
                          toggleConfirm(() =>
                            postTag({
                              object_id: item.id,
                              tag_name: tag,
                              tagMethod: "remove_tags/",
                            })
                          )
                        }
                      />
                    );
                  })}
                </CSSTransitionGroup>
              )}
            </div>
            {is_review_aspect_attribute &&
              is_review_aspect_attribute === "true" &&
              item.fameai_tags &&
              Array.isArray(item.fameai_tags) &&
              item.fameai_tags.length > 0 && (
                <div className="d-flex align-items-start gap-10 mt-10">
                  <Box
                    sx={{
                      backgroundColor: "#00B5AD",
                      borderRadius: "50px",
                      padding: "4px 12px",
                      height: "21px",
                      minWidth: "115px",
                    }}
                    className="d-flex align-items-center gap-6"
                  >
                    <img
                      src={whiteTag}
                      className="size-15"
                      alt=""
                      loading="lazy"
                    />
                    <span
                      style={{
                        color: "white",
                        fontSize: "11px",
                        fontWeight: "400",
                      }}
                    >
                      Fame AI Tags
                    </span>
                  </Box>
                  <CSSTransitionGroup
                    transitionName="reviews-tags"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                    className="d-flex flex-wrap gap-10 align-items-center"
                  >
                    {item.fameai_tags.map((fame_tag, index) =>
                      renderTagChip(fame_tag, index, item.id)
                    )}
                    <img
                      src={timeClock}
                      onClick={() =>
                        handleSetState({
                          viewTagActivityModal: true,
                          review_tag_id: item.id,
                        })
                      }
                      alt=""
                      className="cursor-pointer"
                      loading="lazy"
                    />
                    <img
                      src={AddPlus}
                      onClick={() =>
                        handleSetState({
                          isNewTag: true,
                          fameAiTagModal: true,
                          remarkId: item.id,
                          selectedTag: "",
                        })
                      }
                      alt=""
                      className="cursor-pointer"
                      loading="lazy"
                    />
                  </CSSTransitionGroup>
                </div>
              )}
            {service_category &&
              service_category === "Restaurant" &&
              item.menu_tags &&
              Array.isArray(item.menu_tags) &&
              item.menu_tags.length > 0 && (
                <div className="d-flex align-items-center gap-10 mt-10">
                  <Box
                    style={{
                      backgroundColor: "#F13A88",
                      borderRadius: "50px",
                      padding: "4px 12px",
                      height: "21px",
                      minWidth: "115px",
                    }}
                    className="d-flex align-items-center gap-6"
                  >
                    <img
                      src={whiteTag}
                      style={{ height: "15px", width: "15px" }}
                    />
                    <span
                      style={{
                        color: "white",
                        fontSize: "11px",
                        fontWeight: "400",
                      }}
                    >
                      Product Tags
                    </span>
                  </Box>
                  <CSSTransitionGroup
                    transitionName="reviews-tags"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                    className="d-flex flex-wrap gap-10"
                  >
                    {item.menu_tags &&
                      Array.isArray(item.menu_tags) &&
                      item.menu_tags.map((menu_tag, index) => {
                        return (
                          <FPChip
                            key={`${index}-review-tags`}
                            size="small"
                            variant="outlined"
                            style={{
                              borderColor:
                                menu_tag.sentiment === "positive"
                                  ? "#81E050"
                                  : menu_tag.sentiment === "negative"
                                  ? "#EC554E"
                                  : menu_tag.sentiment === "neutral"
                                  ? "#FAE25A"
                                  : "#BFC2CB",
                              borderWidth: "1.5px",
                            }}
                            className="review-tag-chip"
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleSetState({
                                    selectedTag: menu_tag,
                                    productTagModal: true,
                                    remarkId: item.id,
                                  })
                                }
                              >
                                {menu_tag.product_name && (
                                  <span>{menu_tag.product_name.trim()}</span>
                                )}
                                {menu_tag.associated_opinion && (
                                  <span>
                                    <HorizontalArrow
                                      height="12px"
                                      width="12px"
                                    />
                                  </span>
                                )}
                                {menu_tag.associated_opinion && (
                                  <span>
                                    {menu_tag.associated_opinion.trim()}
                                  </span>
                                )}
                                {menu_tag.product_rating && (
                                  <span>
                                    <HorizontalArrow
                                      height="12px"
                                      width="12px"
                                    />
                                  </span>
                                )}
                                {menu_tag.product_rating && (
                                  <>
                                    <CustomStarRatingComponent
                                      name=""
                                      starCount={1}
                                      style={{ fontSize: "10px" }}
                                      value={utils.setDecimalIndex(
                                        menu_tag.product_rating
                                      )}
                                      editing={false}
                                    />
                                    <span className="font-blue-gray">
                                      {utils.setDecimalIndex(
                                        menu_tag.product_rating
                                      )}
                                    </span>
                                  </>
                                )}
                              </Box>
                            }
                          />
                        );
                      })}
                  </CSSTransitionGroup>
                </div>
              )}
          </div>
          <div className="d-flex gap-10">
            {(CurrentItemId !== item.id || !isPopupOpen) && (
              <div>
                {item?.is_reply_allow && (
                  <div className="d-flex gap-10 text-nowrap">
                    <MessageBox
                      as="button"
                      disable={replyDisabled}
                      className={"messageBoxType-btn-small"}
                      backgroundColor="#438E96"
                      onClick={() => {
                        !replyDisabled && handleReplay(item);
                      }}
                    >
                      <span className="font-white fp-size-13 d-flex justify-content-center">
                        Reply <ReplyOutlinedIcon />
                      </span>
                    </MessageBox>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </section>
    </CardWrapper>
  );
}

export default ReviewListSection;
