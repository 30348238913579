import React from "react";
import PageCard from "./pageCard";
import { FirstFeedbackPage } from "../../../images/svg";

const WelcomePage = () => {
  return (
    <div className="w-100 h-100 d-flex flex-column align-content-center justify-content-center">
    
      <div className="welcome-image-container">
        <FirstFeedbackPage style={{ width: "90%", height: "90%" }} />
      </div>
      <p style={{fontWeight:"450", fontSize:"18px", textAlign:"center", color:"#1B1C1D"}}>Your feedback matters a lot to us.</p>

    </div>
  );
};

export default WelcomePage;
